import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { OurstaffComponent } from './ourstaff/ourstaff.component';
import { ServicesComponent } from './services/services.component';
import { ProductsComponent } from './products/products.component';
import { RentalsComponent } from './rentals/rentals.component';
import { MusiclessonsComponent } from './musiclessons/musiclessons.component';
import { ContactusComponent } from './contactus/contactus.component';
import { ReturnpolicyComponent } from './returnpolicy/returnpolicy.component';
import { SecuritypolicyComponent } from './securitypolicy/securitypolicy.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutusComponent,
    OurstaffComponent,
    ServicesComponent,
    ProductsComponent,
    RentalsComponent,
    MusiclessonsComponent,
    ContactusComponent,
    ReturnpolicyComponent,
    SecuritypolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule, 
    MatCardModule,
    MatDividerModule,
    MatGridListModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatRadioModule,
    MatSidenavModule,
    MatStepperModule,
    MatToolbarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
