import { Component, OnInit } from '@angular/core';
import { Carousel } from '../shared/components/models/carousel.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

  carouselImagePath: string = "./assets/uploads/carousel/carousel1.png";
  carouselImages: Carousel[] = [];
  currentCarousel: Carousel = null;
  private timer;

  constructor() { }

  ngOnInit() {
    this.carouselImages.push(new Carousel(1, "1", "", "", "png"));
    this.carouselImages.push(new Carousel(2, "2", "", "", "png"));
    this.carouselImages.push(new Carousel(3, "3", "", "", "png"));

    this.getNextCarousel();
    setInterval(() => {
      this.getNextCarousel();
    }, 3000);
  }

  getNextCarousel() {
    if (this.currentCarousel == null) {
      this.currentCarousel = this.carouselImages[0];
    } else {
      var currentIndex = this.carouselImages.indexOf(this.currentCarousel);
      if (currentIndex >= this.carouselImages.length - 1) {
        this.currentCarousel = this.carouselImages[0];
      } else {
        this.currentCarousel = this.carouselImages[currentIndex + 1];
      }
    }
    this.carouselImagePath = "./assets/uploads/carousel/carousel" + this.currentCarousel.Id + "." + this.currentCarousel.FileExt;
  }

  getPreviousCarousel() {
    if (this.currentCarousel == null) {
      this.currentCarousel = this.carouselImages[0];
    } else {
      var currentIndex = this.carouselImages.indexOf(this.currentCarousel);
      if (currentIndex <= 0) {
        this.currentCarousel = this.carouselImages[this.carouselImages.length - 1];
      } else {
        this.currentCarousel = this.carouselImages[currentIndex - 1];
      }
    }
    this.carouselImagePath = "./assets/uploads/carousel/carousel" + this.currentCarousel.Id + "." + this.currentCarousel.FileExt;
  }
}
