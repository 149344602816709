import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rentals',
  templateUrl: './rentals.component.html',
  styleUrls: ['./rentals.component.less']
})
export class RentalsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
