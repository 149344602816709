import {Injectable, ComponentFactory } from '@angular/core';

export class Carousel {
  constructor(
    public Id: number, 
    public Name: string,
    public Url: string,
    public Caption: string,
    public FileExt: string
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export abstract class CarouselAdapter {
  static mapGet(obj: any): Carousel {
    return new Carousel(
      obj.id,
      obj.name,
      obj.url, 
      obj.caption,
      obj.fileExt
    );
  }

  static mapSetNoId(obj: Carousel): any {
    return { 
      Name: obj.Name,
      Url: obj.Url,
      Caption: obj.Caption,
      FileExt: obj.FileExt
    };
  }

  static mapSet(obj: Carousel): any {
    return {
      Id: obj.Id,
      Name: obj.Name,
      Url: obj.Url,
      Caption: obj.Caption,
      FileExt: obj.FileExt
    }
  }
}