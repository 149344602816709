import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { OurstaffComponent } from './ourstaff/ourstaff.component';
import { ServicesComponent } from './services/services.component';
import { ProductsComponent } from './products/products.component';
import { RentalsComponent } from './rentals/rentals.component';
import { MusiclessonsComponent } from './musiclessons/musiclessons.component';
import { ContactusComponent } from './contactus/contactus.component';
import { ReturnpolicyComponent } from './returnpolicy/returnpolicy.component';
import { SecuritypolicyComponent } from './securitypolicy/securitypolicy.component';


const routes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'aboutus', component: AboutusComponent},
  // {path: 'ourstaff', component: OurstaffComponent},
  // {path: 'services', component: ServicesComponent},
  // {path: 'products', component: ProductsComponent},
  // {path: 'rentals', component: RentalsComponent},
  // {path: 'musiclessons', component: MusiclessonsComponent},
  // {path: 'contactus', component: ContactusComponent},
  {path: 'returnpolicy', component: ReturnpolicyComponent},
  {path: 'securitypolicy', component: SecuritypolicyComponent},
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
